export enum PermissionCode {
  _, // All

  // templates
  template_search_view,
  template_create_update,
  template_download_document,
  template_upload_document,
  template_detail_history,

  // business rules
  business_rules_search_view,
  business_rules_update,

  // Imports
  imports_search_view,
  imports_download_xml,
  imports_status_update,
}
